const dataAbout = [
    {
        id: 1,
        title: 'Unique, 1/1 generated',
        desc: 'We provide a set of explicit characteristics with different degrees of rarity, some traits are expected to appear around 1% of the collection, introducing the sentiment of uncommonness.',
    },
    {
        id: 2,
        title: '90+ traits in 8 categories',
        desc: 'Each ASIA is unique and programmatically generated from over 90+ possible traits, including expression, headwear, clothing, piercings, and more. All DOGS are awesome, but some are rarer than others.',
    },
    {
        id: 3,
        title: 'Rarity',
        desc: 'Find the rarest combinations, there are no price tiers; ASIA collectibles costs the same for everyone.',
    },
]

export default dataAbout;