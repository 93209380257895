const menus = [
  {
    id: 1,
    name: "Home",
    links: "/",
  },
  {
    id: 2,
    name: "About",
    links: "#",
    namesub: [
      {
        id: 1,
        sub: "Team Details",
        links: "/team-details",
      },
      {
        id: 2,
        sub: "FAQ",
        links: "/faq",
      },
    ],
  },
  {
    id: 3,
    name: "Digital Collection",
    links: "#",
    namesub: [
      {
        id: 1,
        sub: "Digital Collection Item",
        links: "/DigitalCollectionDetails",
      },
      {
        id: 2,
        sub: "Digital Collection",
        links: "/DigitalCollectionItem",
      },
    ],
  },
  {
    id: 4,
    name: "FanClub",
    links: "/FanClub",
  },
];

export default menus;