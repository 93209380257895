import { useState } from 'react';
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";

export default function App() {
  const [mintAmount, setMintAmount] = useState(1);
  const nftCost = 15;
  const clientId = '7d042bc6-18f4-4853-9558-c8cfd2f8a0ac';


  const handleDecrement = () => {
    if (mintAmount <= 1) return;
    setMintAmount(mintAmount - 1);
  }

  const handleIncrement = () => {
    if (mintAmount >= 10) return;
    setMintAmount(mintAmount + 1);
  }

  // console.log(mintAmount);

  return (
    
    <><div className='buttonsContainer'><button className='multiButtons nft-item tf-button btn-effect' onClick={handleDecrement}> - </button><input className='multiInput' readOnly type="number" value={mintAmount} /><button className='multiButtons nft-item tf-button btn-effect' onClick={handleIncrement}> + </button></div><div className='crossmint-container'>
      <CrossmintPayButton
        clientId={clientId}
        className="xmint-btn"
        // environment="staging"
        paymentMethod="ETH"
        mintConfig={{
          type: "erc-721",
          totalPrice: (nftCost * mintAmount).toString(),
          _mintAmount: mintAmount
        }} />
      <CrossmintPayButton
        clientId={clientId}
        className="xmint-btn"
        // environment="staging"
        paymentMethod="SOL"
        mintConfig={{
          type: "erc-721",
          totalPrice: (nftCost * mintAmount).toString(),
          _mintAmount: mintAmount
        }} />
      <CrossmintPayButton
        clientId={clientId}
        className="xmint-btn"
        // environment="staging"
        paymentMethod="fiat"
        mintConfig={{
          type: "erc-721",
          totalPrice: (nftCost * mintAmount).toString(),
          _mintAmount: mintAmount
        }} />
    </div></>
  );
}
