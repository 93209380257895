const dataFaq = [
    {
        id: 1,
        title: 'What are the Digital Collectibles?',
        content: 'Digital Collectibles are assets that have been tokenized via a blockchain. They are assigned unique identification codes and metadata that distinguish them from other tokens.',
        show: 'show'
    },
    {
        id: 2,
        title: 'How do i get Digital Collectibles?',
        content: 'NFTs can be traded and exchanged for money, cryptocurrencies, or other NFTs—it all depends on the value the market and owners have placed on them.',
    },
    {
        id: 3,
        title: 'What is CrossMint?',
        content: 'Crossmint is trusted by more than 10,000 brands and developers. From independent NFT creators and collections, to companies like Magic Eden, Rarible, Origin Protocol, Diageo, Cinemark, Tomorrowland, and McFarlane Toys.',
    },
    {
        id: 4,
        title: 'Who is behind the project?',
        content: 'Rodolfo Pizarro is the CEO behind the project. Rodolfo is an international soccer star.',
    },
    {
        id: 5,
        title: 'How can we buy your NFTs?',
        content: 'Urna vitae erat et lacus, consectetur ac nulla vestibulum lobortis. Nulla dapibus urna volutpat venenatis, risus faucibus.',
    },
]

export default dataFaq;