import React from "react";
import Button from "../components/button";
import img from "../assets/images/common/img1.png";
import img2 from "../assets/images/common/img7.png";
import img3 from "../assets/images/common/img13.png";
import { Milestone } from "../components/milestone/milestone";
import { useState, useEffect } from "react";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useReadContract, useAccount, useDisconnect } from "wagmi";
import { abi } from "../abi";

function FanClub(props) {
  const [testAdress] = useState("0x575012c557803F61B537D0EDbaCef23ad9558d2a");

  const { address, isConnected } = useAccount();
  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const contractAddress = "0x1E7E64E37316B2a10B8F94e18978D8a40A7193E9";

  const { data: contractData = "", refetch: refetchData } = useReadContract({
    abi,
    address: contractAddress,
    functionName: "balanceOf",
    // args: [testAdress],
    args: [address],
  });

  const handleButtonClick = () => {
    console.log(contractData);
  };

  return (
    <section className="page-title">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="breadcrumbs center mb-5"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <h2>Join the Fan Club</h2>
              <p>Get exclusive benefits with your NFTs</p>
              <div className="wallet-connect-b"></div>
              <div className="d-flex justify-content-center">
                <button onClick={isConnected ? disconnect : open}>
                  {" "}
                  {isConnected ? "Connected!" : "Connect Wallet"}{" "}
                </button>
              </div>
              {/* <button onClick={()=>handleButtonClick()}>test</button> */}
              {/* <p> {isConnected ? `Connected: ${address}` : ""}</p> */}
              <p> {isConnected ? `Total Tokens: ${contractData}` : ""}</p>
            </div>
          </div>
        </div>

        <div className="row mb-5" data-aos="fade-up" data-aos-duration="800">
          <div className="col-md-12 center">
            <p className="desc">your progress</p>
            <Milestone nftnumber={contractData.toString() || 0} />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-12"></div>
        <div className="col-xl-5 col-lg-5 col-md-12">
          <div
            className="item-details col-xl-10 center"
            data-aos="fade-up"
            data-aos-duration="800"
          >
            <h4>Benefits</h4>
          </div>
        </div>
      </div>

      <div className="row mb-5">
        <div className="col-xl-6 col-lg-6 col-md-12">
          <div
            className="image-details"
            data-aos="fade-right"
            data-aos-duration="800"
          >
            <img src={img} alt="" />
          </div>
        </div>
        <div className="col-xl-5 col-lg-5 col-md-12 d-flex justify-content-center align-content-center flex-wrap box-border">
          <div
            className="item-details col-xl-10 d-flex flex-column justify-content-around h-100"
            data-aos="fade-left"
            data-aos-duration="800"
          >
            <h6>1 NFT - Discord Access</h6>
            <p className="desc">
              Gain access to our exclusive Discord community, a vibrant hub for
              holders of our coveted NFT collection. Engage in meaningful
              discussions, connect with fellow enthusiasts, and stay up-to-date
              on the latest project developments. Your NFT serves as your key to
              this privileged realm, granting you a voice in shaping the future
              of our community.
            </p>
            <a href="https://discord.gg/zAkWSaTKkK" target="_blank" className="tf-button btn-effect">
              <span className="boder-fade"></span>
              <span className="effect">Join the discord</span>
            </a>
          </div>
        </div>
      </div>

      <div className="row mb-5">
        <div className="col-xl-6 col-lg-6 col-md-12">
          <div
            className="image-details"
            data-aos="fade-right"
            data-aos-duration="800"
          >
            <img src={img2} alt="" />
          </div>
        </div>
        <div className="col-xl-5 col-lg-5 col-md-12 d-flex justify-content-center align-content-center flex-wrap box-border">
          <div
            className="item-details col-xl-10 d-flex flex-column justify-content-around h-100"
            data-aos="fade-left"
            data-aos-duration="800"
          >
            <h6>5 NFTs Exclusive Media</h6>
            <p className="desc">
              Your ownership of our coveted NFT grants you exclusive access to a
              series of thrilling raffles, offering you the chance to win
              exciting media prizes and elevate your entertainment experience.
              With each raffle, you'll have the opportunity to acquire premium
              media content, including personalized Voicemails and Videos
            </p>
            <a href="" target="_blank" className="tf-button btn-effect">
              <span className="boder-fade"></span>
              <span className="effect">Get Media</span>
            </a>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-12">
          <div
            className="image-details"
            data-aos="fade-right"
            data-aos-duration="800"
          >
            <img src={img3} alt="" />
          </div>
        </div>
        <div className="col-xl-5 col-lg-5 col-md-12 d-flex justify-content-center align-content-center flex-wrap box-border">
          <div
            className="item-details col-xl-10 d-flex flex-column justify-content-around h-100"
            data-aos="fade-left"
            data-aos-duration="800"
          >
            <h6>10 NFTs Merchandise</h6>
            <p className="desc">
              Owning our prized NFT grants you exclusive access to a line of
              high-quality merchandise, enabling you to showcase your passion
              for our project and represent your membership in our elite
              community. With each item, you'll own a tangible piece of our
              brand, embodying the spirit of innovation and creativity that lies
              at the heart of our project.
            </p>
            <a href="" target="_blank" className="tf-button btn-effect">
              <span className="boder-fade"></span>
              <span className="effect">Get Merch</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FanClub;
