import bgImg1 from "../images/background/sand-bg.jpeg";
import bgImg2 from "../images/background/graph-bg.jpg";
import img1 from '../images/common/16.png';

const dataSlider = [
    {
        id: 1,
        title: 'Pizarro nft collections',
        desc : 'Be part of this exclusive NFT art collection with 3,333 unique digital portraits of Asia the Chow Chow Dog. Brought to you by Rodolfo Pizarro.',
        bgImg: bgImg1,
        img : img1
    },

]

export default dataSlider;