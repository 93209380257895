import React, { useState, useEffect } from "react";
import "./styles.scss";
import { Tooltip } from "react-tooltip";

export const Milestone = (props) => {
  const nftNumber = props.nftnumber;
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (nftNumber==0){
        setProgress(0)
    }
    if (nftNumber == 1) {
      setProgress(20);
    }
    if(nftNumber>1){
      setProgress(nftNumber * 4 + 20);
    }
  }, [nftNumber]);

  const handleButtonClick = () => {
    console.log(nftNumber);
  };

  const handleResetClick = () => {
    console.log(progress);
  };

  return (
    <div className="container">
      <div className="bar-container">
        <div className="progress-bar bg-transparent">
          <div className="circle-container d-flex">
            <div className="circle center d-flex justify-content-end">
              <div
                className="circ-ins"
                data-tooltip-id="my-tooltip"
                data-tooltip-content={"testing"}
                data-tooltip-place="top"
              >
                1
              </div>
            </div>
            <div className="circle center d-flex justify-content-end">
              <div
                className="circ-ins"
                data-tooltip-id="my-tooltip"
                data-tooltip-content={"testing"}
                data-tooltip-place="bottom"
              >
                5
              </div>
            </div>
            <div className="circle center d-flex justify-content-end">
              <div className="circ-ins">10</div>
            </div>
            <div className="circle center d-flex justify-content-end">
              <div className="circ-ins">15</div>
            </div>
            <div className="circle center d-flex justify-content-end">
              <div className="circ-ins">20</div>
            </div>
          </div>

          <div className="bar-fill-container d-flex align-items-center">
            <div
              className="progress-bar-fill"
              style={{ width: `${progress}%` }}
            >
              {" 1"}
            </div>
          </div>
          <Tooltip id="my-tooltip" />
        </div>
      </div>
      <div>
        {/* <button onClick={handleButtonClick}>progress</button>
        <button onClick={handleResetClick}>reset</button> */}
        {/* <p>{nftNumber}</p> */}
      </div>
    </div>
  );
};
