import HomeOne from "./HomeOne";
import AboutOne from "./AboutOne";
import AboutTwo from "./AboutTwo";
import ComingSoon from "./ComingSoon";
import Community from "./Community";
import FAQ from "./FAQ";
import Page404 from "./404";
import RoadMapOne from "./RoadMapOne";
import RoadMapTwo from "./RoadMapTwo";
import DigitalCollectionItem from "./DigitalCollectionItem";
import DigitalCollectionDetails from "./DigitalCollectionItemDetails";
import Blog from "./Blog";
import BlogDetails from "./BlogDetails";
import Contact from "./Contact";
import TeamDetail from "./TeamDetail";
import FanClub from "./FanClub";
import Verification from "./Verification";


const routes = [
  { path: '/', component: <HomeOne />},
  { path: '/about-v1', component: <AboutOne />},
  { path: '/about-v2', component: <AboutTwo />},
  { path: '/coming-soon', component: <ComingSoon />},
  { path: '/community', component: <Community />},
  { path: '/faq', component: <FAQ />},
  { path: '/404', component: <Page404 />},
  { path: '/road-map-v1', component: <RoadMapOne />},
  { path: '/road-map-v2', component: <RoadMapTwo />},
  { path: '/DigitalCollectionItem', component: <DigitalCollectionItem />},
  { path: '/DigitalCollectionDetails', component: <DigitalCollectionDetails />},
  { path: '/blog', component: <Blog />},
  { path: '/blog-details', component: <BlogDetails />},
  { path: '/contact', component: <Contact />},
  { path: '/team-details', component: <TeamDetail />},
  { path: '/FanClub', component: <FanClub />},
  { path: '/verification', component: <Verification/>}
]

export default routes;