const dataWork = [
    {
        id: 1,
        numb: '01',
        title: 'Set up your wallet',
        text : 'With Crossmint you can have a wallet in an instant, and transfer your collection free of charge to any other wallet'
    },
    {
        id: 2,
        numb: '02',
        title: 'Buy your collection',
        text : 'You can buy your collection with ease using crossmint buttons for different types of currency'
    },
    {
        id: 3,
        numb: '03',
        title: "Add your Digital Collection",
        text : 'Grow bigger your with your Digital Collection'
    },
    {
        id: 4,
        numb: '04',
        title: "Sell Your Collectibles",
        text : 'Your owned Collectibles will appear on openSea marketplace for you to trade or sell them at anytime.'
    },
]
export default dataWork;