
import img1 from '../images/logo/logo.png'
import img2 from '../images/logo/logo.png'
import img3 from '../images/logo/logo.png'
import img4 from '../images/logo/logo.png'
import img5 from '../images/logo/logo.png'
import img6 from '../images/logo/logo.png'
import img7 from '../images/logo/logo.png'
import img8 from '../images/logo/logo.png'
import img9 from '../images/logo/logo.png'
import img10 from '../images/logo/logo.png'
import img11 from '../images/logo/logo.png'
import img12 from '../images/logo/logo.png'
import img13 from '../images/logo/logo.png'
import img14 from '../images/logo/logo.png'
import img15 from '../images/logo/logo.png'
import img16 from '../images/logo/logo.png'
import img17 from '../images/logo/logo.png'
import img18 from '../images/logo/logo.png'
// import img19 from '../images/logo/logo.png'
// import img20 from '../images/logo/logo.png'
// import img21 from '../images/logo/logo.png'
// import img22 from '../images/logo/logo.png'
// import img23 from '../images/logo/logo.png'
// import img24 from '../images/logo/logo.png'
// import img25 from '../images/logo/logo.png'
// import img26 from '../images/logo/logo.png'
// import img27 from '../images/logo/logo.png'
// import img28 from '../images/logo/logo.png'
// import img29 from '../images/logo/logo.png'
// import img30 from '../images/logo/logo.png'
// import img31 from '../images/logo/logo.png'
// import img32 from '../images/logo/logo.png'
// import img33 from '../images/logo/logo.png'
// import img34 from '../images/logo/logo.png'
// import img35 from '../images/logo/logo.png'
// import img36 from '../images/logo/logo.png'
// import img37 from '../images/logo/logo.png'
// import img38 from '../images/logo/logo.png'
// import img39 from '../images/logo/logo.png'
// import img40 from '../images/logo/logo.png'
// import img41 from '../images/logo/logo.png'
// import img42 from '../images/logo/logo.png'
// import img43 from '../images/logo/logo.png'
// import img44 from '../images/logo/logo.png'
// import img45 from '../images/logo/logo.png'
// import img46 from '../images/logo/logo.png'
// import img47 from '../images/logo/logo.png'
// import img48 from '../images/logo/logo.png'
// import img49 from '../images/logo/logo.png'
// import img50 from '../images/logo/logo.png'
// import img51 from '../images/logo/logo.png'
// import img52 from '../images/logo/logo.png'
// import img53 from '../images/logo/logo.png'
// import img54 from '../images/logo/logo.png'
// import img55 from '../images/logo/logo.png'
// import img56 from '../images/logo/logo.png'
// import img57 from '../images/logo/logo.png'
// import img58 from '../images/logo/logo.png'
// import img59 from '../images/logo/logo.png'

const dataPartner = [
    {
        img: img1,
    },
    {
        img: img2,
    },
    {
        img: img3,
    },
    {
        img: img4,
    },
    {
        img: img5,
    },
    {
        img: img6,
    },
    {
        img: img7,
    },
    {
        img: img8,
    },
    {
        img: img9,
    },
    {
        img: img10,
    },
    {
        img: img11,
    },
    {
        img: img12,
    },
    {
        img: img13,
    },
    {
        img: img14,
    },
    {
        img: img15,
    },
    {
        img: img16,
    },
    {
        img: img17,
    },
    {
        img: img18,
    },
]

export default dataPartner;