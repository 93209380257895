import React from "react";
import Button from "../components/button";
import { Spinner } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useReadContract, useAccount, useDisconnect } from "wagmi";
import { abi } from "../abi";

function Verification() {
  const [discordUser, setDiscordUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const { address, isConnected } = useAccount();
  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const contractAddress = "0x1E7E64E37316B2a10B8F94e18978D8a40A7193E9";

  const { data: contractData = "", refetch: refetchData } = useReadContract({
    abi,
    address: contractAddress,
    functionName: "balanceOf",
    // args: [testAdress],
    args: [address],
  });

  const handleVerify = async (userId) => {
    setLoading(true);
    await fetch("http://localhost:53134/verify", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId }),
    })
      // .then((response) => response.json())
      .then((response) => {
        if (response.status == 200) {
          console.log("correct!");
          setVerified(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
  };

  useEffect(() => {
    // parse access_token and token_type that were inserted by Discord into redirect URL
    const fragment = new URLSearchParams(window.location.hash.slice(1));
    const [accessToken, tokenType] = [
      fragment.get("access_token"),
      fragment.get("token_type"),
    ];
    const fetchUsers = () => {
      fetch("https://discord.com/api/users/@me", {
        headers: {
          authorization: `${tokenType} ${accessToken}`,
        },
      })
        .then((result) => result.json())
        .then((response) => {
          setDiscordUser({
            id: response.id,
            username: response.username,
            avatar: response.avatar,
          });
        })
        .catch(console.error);
    };

    if (accessToken) {
      fetchUsers();
    }
  }, []);

  return (
    <section className="page-title">
      <div className="container"></div>

      <div className="row">
        <div className="col-xl-5 col-lg-5 col-md-12">
          <div
            className="item-details col-xl-10 center"
            data-aos="fade-up"
            data-aos-duration="800"
          >
            <h4>Benefits</h4>
          </div>
        </div>
      </div>

      <div className="row mb-5 d-flex justify-content-center">
        <div className="col-xl-11 d-flex justify-content-center align-content-center flex-wrap box-border">
          <div
            className="item-details col-xl-10 d-flex flex-column justify-content-around h-100"
            data-aos="fade-left"
            data-aos-duration="800"
          >
            <h6>Link your Discord Account</h6>
            <p className="desc">
              Verify your Discord account in order to gain access to an
              exclusive role and more
            </p>

            <div className="discord-column">
              {/* You can render the user information here */}
              {discordUser && (
                <>
                  {/* <p>ID: {discordUser.id}</p> */}
                  <img
                    src={`https://cdn.discordapp.com/avatars/${discordUser.id}/${discordUser.avatar}.png`}
                    alt="User Avatar"
                    style={{ borderRadius: "50%" }}
                  />
                  <p>{discordUser.username}</p>
                </>
              )}
            </div>

            <div className="d-flex justify-content-center">
              <button onClick={isConnected ? disconnect : open}>
                {" "}
                {isConnected ? "Connected!" : "Connect Wallet"}{" "}
              </button>
            </div>
            {isConnected && (
              <div className="d-flex justify-content-center">
                <p>{"You have " + contractData.toString() + " tokens"}</p>
              </div>
            )}
            {discordUser !== null &&
              isConnected &&
              contractData.toString() >= 1 && !verified &&(
                <a
                  onClick={() => handleVerify(discordUser.id)}
                  className="tf-button btn-effect"
                >
                  <span className="boder-fade"></span>
                  {!loading && <span className="effect">Verify</span>}
                  {loading && (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  )}
                </a>
              )}
            {verified && (
              <div className="d-flex justify-content-center mb-5 box-border">
                Verification Complete!, you're now able to see channels
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
export default Verification;
